.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f4f7; /* Light pastel blue background consistent with other pages */
}

.login-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 300px; /* Ensures that the card size is constant */
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 20px;
    width: 100%; /* Ensures that input groups fill the card */
}

.input-group input[type="text"],
.input-group input[type="password"] {
    width: 100%; /* ensures inputs fill their container */
    padding: 10px; /* uniform padding */
    margin: 0; /* no additional space around inputs */
    border: 1px solid #ccc; /* standard light grey border */
    border-radius: 4px; /* soft rounded corners */
    box-sizing: border-box; /* border and padding included in width */
    font-size: 14px; /* readable font size */
    background-color: #ffffff; /* clear white background */
}

.login-button {
    background-color: #ff94c2; /* Pastel pink to match buttons on other pages */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: auto; /* Prevents stretching to fit container */
}

.login-button:hover {
    background-color: #ec829a; /* Darker pink for hover effect */
}

.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 10px;
    text-align: center; /* centering error messages */
}

h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333; /* Consistent dark text color */
}
