/* PendingForms.css */

.pending-forms-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: flex-start; /* Align content to start, gives room for the button */
    align-items: center; /* Center-align the items horizontally */
    height: 100vh; /* Use full view height */
    background-color: #f0f4f7; /* Consistent light pastel blue background */
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between buttons */
    margin-bottom: 20px; /* Space above the table */
}

.pending-volver-button, .pending-refresh-button, .view-button, .edit-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #ff94c2; /* Pastel pink */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    min-width: 100px; /* Ensuring all buttons have at least the same width */
    margin-top: 10px; /* Margin around each button to ensure they don't touch */
}

.pending-volver-button:hover, .pending-refresh-button:hover, .view-button:hover, .edit-button:hover {
    background-color: #ec729b; /* A slightly darker pink for hover effect */
}

.pending-forms-table {
    width: 100%; /* Full width of the container */
    max-width: 1024px; /* Optional: limit the max width for better readability */
    margin-bottom: 20px; /* Space at the bottom of the table */
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
}

.pending-forms-table th,
.pending-forms-table td {
    padding: 12px;
    text-align: center; /* Center align text horizontally */
    vertical-align: middle; /* Center align text vertically */
    border-bottom: 1px solid #ddd;
}

.pending-forms-table th {
    background-color: #ff94c2; /* Pastel pink */
    color: white;
}

.pending-forms-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Alternate row color for readability */
}

.pending-forms-table tbody tr:hover {
    background-color: #ddd; /* Hover effect */
}

/* Adding a container for action buttons in each table row to manage spacing */
.action-buttons {
    display: flex;
    gap: 10px; /* Space between action buttons */
}
