/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

/* Base settings for the form container */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f0f4f7; /* Very light pastel blue background for the whole page */
    font-family: 'Roboto', sans-serif;
}

/* Styling the form to look more structured and visually separated */
.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 24px;
    background-color: #ffffff; /* White background for the form */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.questions {
    background-color: #e9f0f8; /* Soft blue for question background */
    border: 1px solid #d1dce5;
    padding: 15px;
    margin-bottom: 20px; /* Space between questions */
    border-radius: 8px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 16px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
select,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 5px;
    background-color: #fff; /* White background for inputs to ensure readability */
}

textarea {
    resize: vertical;
    height: 120px;
}

button {
    width: 100%;
    padding: 10px;
    color: white;
    background-color: #ff94c2; /* Pastel pink for buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #ec829a;
}
