/* SuccessPage.css */

/* Importing Google Fonts used on FormPage */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f4f7; /* Very light pastel blue background for the whole page */
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    text-align: center;
}

.success-card {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 500px;
}

.success-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.success-text {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.success-button {
    padding: 8px 16px;
    color: white;
    background-color: #ff94c2; /* Pastel pink */
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
    max-width: fit-content; /* Ensures the button is only as wide as its content */
    margin: 5px 10px 5px 0; /* Right margin to add space between buttons */
}

.success-button:hover {
    background-color: #ec829a; /* Darker shade of pink for hover effect */
}
