/* EditForm.css */

.edit-form {
    display: flex;
    flex-direction: column;
    max-width: 600px; /* Adjust based on your layout needs */
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-form h1 {
    color: #ff94c2; /* Your project's typical header color */
}

.edit-form label {
    margin-bottom: 10px;
    color: #333; /* Standard text color */
}

.edit-form input[type="text"],
.edit-form input[type="date"],
.edit-form select,
.edit-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.edit-form input[type="radio"] {
    margin-right: 5px;
}

.edit-form button {
    width: 100%;
    padding: 10px 20px;
    background-color: #ff94c2; /* Matching button colors to your theme */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.edit-form button:hover {
    background-color: #ec729b; /* Darker shade for hover effect */
}